import React from "react"
import Layout from "../../components/layout"

import PDFfile from "./humana-plan-1.pdf"

export default function Plan1() {
  return (
    <Layout>
      <div style={{ paddingTop: "200px", paddingBottom: "100px" }}>
        <div className="container text-center">
          <h1 className="text-blue text-center mb-5">PEC Humana Plan 1 Details</h1>
          {/* <iframe
            src="https://view.officeapps.live.com/op/embed.aspx?src=https://res.cloudinary.com/dwzjvi83z/raw/upload/v1611639859/humana-5050-canopy-option-4_khu4ss.pptx"
            height="600px"
            frameborder="0"
            style={{ width: "100%" }}
            title="About PeerSource"
          >
            This is an embedded{" "}
            <a target="_blank" rel="noreferrer" href="https://office.com">
              Microsoft Office
            </a>{" "}
            presentation, powered by{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://office.com/webapps"
            >
              Office
            </a>
            .
          </iframe> */}

          <a class="common-btn" href={PDFfile} download>
            Download File
          </a>
        </div>
      </div>
    </Layout>
  )
}
